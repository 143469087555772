<template>
  <div class="cotactSpace">
    <div class="text-center mb-2">
      <h2><b>お問い合わせ</b></h2>
      <div class="">
        下記の項目にご記入ください。
      </div>
    </div>
    <b-card
      title=""
    >
      <b-form>
        <div class="formSpace_">
          <b-row
            v-for="(item, index) in menulist"
            :key="index"
          >
            <InputParts
              v-if="useValueDicList.indexOf(item.type) !== -1"
              :label="item.label"
              :type="item.type"
              :choice="typeof item.choice !== 'undefined'? item.choice: []"
              :value-dic="inputForm[item.key]"
              :default-value="typeof item.defaultValue !== 'undefined'? item.defaultValue: []"
              :disabled="typeof item.disabled !== 'undefined'? item.disabled: false"
              :explain="typeof item.explain !== 'undefined'? item.explain: []"
              :select="item.type ==='checkbox' ? choice: []"
              @inputData="inputForm[item.key] = $event"
            />
            <InputParts
              v-else
              :label="item.label"
              :type="item.type"
              :description="item.description"
              :choice="typeof item.choice !== 'undefined'? item.choice: []"
              :value="item.type !=='checkbox' ?inputForm[item.key]: ''"
              :default-value="typeof item.defaultValue !== 'undefined'? item.defaultValue: []"
              :disabled="typeof item.disabled !== 'undefined'? item.disabled: false"
              :explain="typeof item.explain !== 'undefined'? item.explain: []"
              :select="item.type ==='checkbox' ? choice: []"
              @inputData="inputForm[item.key] = $event"
            />
          </b-row>
        </div>
        <div class="error_ text-center">
          {{ msgError }}
        </div>
        <b-row class="mt-1 mb-3 text-center">
          <!-- submit and reset -->
          <b-col>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="confirm"
            >
              送信する
              <b-spinner
                v-if="status === 2"
                small
              />
            </b-button>
          </b-col>
        </b-row>

      </b-form>
      <!-- <SuccessBasic
        ref="sbchild"
        to-page="student-profile"
      /> -->
    </b-card>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BRow, BCol, BForm, BButton, BCard, BSpinner,
} from 'bootstrap-vue'
import InputParts from '@/components/molecules/input/InputParts.vue'
import menulist from '@/components/conf/お問い合わせ.json'

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BButton,
    BCard,
    InputParts,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      inputForm: {},
      status: 1,
      isShow: false,
      menulist,
      useValueDicList: ['checkbox', 'selectSingle'],
      msgError: '',
    }
  },
  computed: {
  },
  mounted() {
  },
  methods: {
    confirm() {
      window.console.log('memo', this.inputForm, Object.keys(this.inputForm).length === 5)
      if (Object.keys(this.inputForm).length === 5) {
        this.msgError = ''
        this.success()
      } else {
        this.msgError = '全ての項目にご記入ください。'
      }
    },
    showBtn() {
      this.$ref.sbchild.$emit('success')
    },
    success() {
      this.$swal({
        title: '登録完了！',
        text: 'お問い合わせ内容が送信されました。',
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      }).then(() => {
        this.$router.push({ name: 'top' })
        this.status = 1
      })
    },
  },
}
</script>

<style scoped>
.cotactSpace {
  margin: 12% 0 0 0;
}
.formSpace_ {
  padding: 4%;
}
.error_ {
  font-weight: 600;
  color: rgb(199, 9, 9);
}
</style>
